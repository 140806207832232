import React, {useState, useRef, useEffect} from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import "./index.css";
import Icon from "../../assets/images/logoIcon.png";
import AiIcon from "../../assets/images/aiIcon.png";
import SendIcon from "../../assets/images/sendIcon.png";
import UserIcon from "../../assets/images/userIcon.png";
import RobotIcon from "../../assets/images/robotIcon.png";
import {Redis} from "https://esm.sh/@upstash/redis";
import Vioce from "../../assets/images/vioce.avif";
import Specking from "../../assets/images/speaking.png";
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import TextToSpeech from "../../textToSpeech";

const redis = new Redis({
  url: "https://hip-mutt-25007.upstash.io",
  token: "AWGvAAIjcDE1YTRmMGE0YWFjOTk0NWFkYmI4MmE2MTg5MDRlNjg0YXAxMA",
});

const ChatPage = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [ready, setReady] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [speaking, setSpeaking] = useState(false);
  const [currentBot, setCurrentBot] = useState("");
  const chatBoxRef = useRef(null);

  const {transcript, listening, resetTranscript, browserSupportsSpeechRecognition} = useSpeechRecognition();

  const startVoiceRecognition = () => {
    resetTranscript();
    if (!browserSupportsSpeechRecognition) {
      return <span>Browser doesn't support speech recognition.</span>;
    }
    console.log("start listening");
    SpeechRecognition.startListening({continuous: false});
  };

  // when speaking page is on and user done specking
  useEffect(() => {
    if (listening) {
      console.log("listening");
    } else {
      console.log("not listening");
      if (speaking) {
        setInput(transcript);
        handleSubmit();
        resetTranscript();
      }
    }
  }, [listening]);

  function getIdFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  }

  const updatePrompt = async () => {
    const id = getIdFromUrl();
    // const data = await redis.get(id);
    if (id) {
      setCurrentBot(id);
    }
  };

  const gettingAgentList = async () => {
    // const agentList = await redis.keys("*");
    // setAgentList(agentList);
    // new api
    let agentListData = [];
    const response = await axios.get("/get_all_index_data");
    if (response.status === 200) {
      response.data.data.forEach(data => {
        agentListData.push(data);
      });
    }
    console.log(agentListData);
    setAgentList(agentListData);
  };

  useEffect(() => {
    const synth = window.speechSynthesis;
    synth.cancel();
    SpeechRecognition.stopListening();
    updatePrompt();
    gettingAgentList();
  }, []);

  const handleSubmit = async () => {
    setReady(true);
    SpeechRecognition.stopListening();
    resetTranscript();
    setInput("");
    const userMessage = {sender: "user", text: speaking ? transcript : input};
    // setSpeaking(false);
    const aiMessage = {sender: "ai", text: "processing..."};
    setMessages(prevMessages => [...prevMessages, userMessage, aiMessage]);

    //request data
    // const requestData = {
    //   prompt: userinput,
    //   question: speaking ? transcript : input,
    //   max_token: 10000,
    // };
    const requestData = {
      query: speaking ? transcript : input,
      name: currentBot,
    };

    // const response = await axios.post("/generate", requestData);
    const response = await axios.post("/ask_api", requestData);
    if (response) {
      const aiMessage = {
        sender: "ai",
        // text: response.data.generated_text.content,
        text: response.data?.generated_text?.content || "Sorry, I can not find an answer to your question",
      };
      //remove the loading message
      setMessages(prevMessages => prevMessages.slice(0, -1));

      setMessages(prevMessages => [...prevMessages, aiMessage]);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const updateCurrentBot = async agent => {
    setLoading(true);
    setCurrentBot(agent);
    setTimeout(() => {
      setMessages([]);
      setLoading(false);
    }, 2000);
  };

  const updateUserInput = async hear => {
    if (hear === true) {
      SpeechRecognition.stopListening();
      setInput(transcript);
      handleSubmit();
      resetTranscript();
    } else if (hear === false) {
      const synth = window.speechSynthesis;
      synth.cancel();

      SpeechRecognition.startListening({continuous: false});
    }
  };

  return (
    <div className="chat-container">
      <header className="chat-header">
        <img src={Icon} alt="Baytowne Logo" className="chat-logo" />
      </header>
      <div className="chat-box" ref={chatBoxRef} style={{overflowY: listening ? "hidden" : ""}}>
        {!ready && (
          <>
            <div className="introductionContainer">
              <div className="ai-Icon">
                <img src={AiIcon} alt="Baytowne Logo" />
              </div>
              <div className="introduction">
                <h1>Ready to Explore Automation?</h1>
                <p>Try our demo and see how a Visios Ai agent can streamline your operations</p>
              </div>
            </div>
            {/* <div className="questionContainer">
              <h3>Prompt Suggestions for you</h3>
              <div className="questions">
                <div className="row1">
                  <p onClick={() => clickonSuggestion(1)}>
                    {" "}
                    <img src={QuestionIcon} alt="add questions" />
                    How can I schedule a maintenance request?
                  </p>
                  <p onClick={() => clickonSuggestion(2)}>
                    <img src={QuestionIcon} alt="add questions" />
                    Do you offer short term lease?
                  </p>
                </div>
                <div className="row2">
                  <p onClick={() => clickonSuggestion(3)}>
                    <img src={QuestionIcon} alt="add questions" />
                    What is the price for a 1 Bedroom apartment?
                  </p>
                  <p onClick={() => clickonSuggestion(4)}>
                    <img src={QuestionIcon} alt="add questions" />
                    Are pets allowed?
                  </p>
                </div>
              </div>
            </div> */}
          </>
        )}
        {messages.map((message, index) => (
          <div className="chatContainer">
            <div key={index} className={`chat-message ${message.sender === "user" ? "user-message" : "ai-message"}`}>
              {message.sender === "user" ? (
                <div className="message-content-user">
                  <div className="messageContentDisplay">
                    <ReactMarkdown>{message.text}</ReactMarkdown>
                  </div>
                  <img src={UserIcon} alt="userIcon" />
                </div>
              ) : (
                <div className="message-content-ai">
                  <img src={RobotIcon} alt="userIcon" />
                  <div className="messageContentDisplay">
                    <ReactMarkdown>{message.text}</ReactMarkdown>
                    {speaking && <TextToSpeech text={message.text} />}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

        {speaking && (
          <div className="speakingContainer">
            <img src={Specking} alt="userIcon" />
            {listening ? <h1>I'm listening... </h1> : <h1>Answering...</h1>}

            <div className="textBox">
              <p className="transcript">{transcript}</p>
            </div>
            <button
              onClick={() => {
                updateUserInput(listening);
              }}
              style={{background: listening ? "" : "red"}}>
              {listening ? "Finish speaking" : "Pause & Ask Question"}
            </button>
          </div>
        )}
      </div>

      <div className="chat-input">
        <input
          type="text"
          placeholder="Type your message"
          value={input}
          onChange={e => {
            setSpeaking(false);
            setInput(e.target.value);
          }}
          onKeyPress={e => e.key === "Enter" && handleSubmit()}
        />
        <button onClick={handleSubmit}>
          {" "}
          <img src={SendIcon} alt="send Logo" />
        </button>
        <button
          id="voiceButton"
          onClick={() => {
            startVoiceRecognition();
            setSpeaking(!speaking);
            const synth = window.speechSynthesis;
            synth.cancel();
          }}>
          {" "}
          <img src={Vioce} alt="speck Logo" />
        </button>
      </div>
      <div className="listOfAgent">
        <h3>list of Ai agents:</h3>
        <ul>
          {agentList.map(agent => (
            <li onClick={() => updateCurrentBot(agent.name)}>{agent.name} </li>
          ))}
        </ul>
      </div>
      {loading && <div className="loadingScreen">Loading...</div>}
    </div>
  );
};

export default ChatPage;
